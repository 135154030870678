import {
  DocumentLinks,
  formatUsd,
  GENERAL_MINIMUM_FOR_DIRECT_INDEXING,
} from "@frec-js/common";
import { FC } from "react";

import { classNames } from "../utils/classNames";
import { FrecWebPath } from "../utils/paths";
import { FootnoteAnchor } from "./Anchor";
import { alphaPercentStr } from "./DirectIndexCalculatorData";
import { Expandable } from "./Expandable";
import { TextLink } from "./Link";

export const Disclaimers: FC<{
  className?: string;
  showBorders?: boolean;
  withAffiliateDisclaimer?: boolean;
  withTwoXMoreDisclaimer?: number;
  withLowerFeesDisclaimerSPY?: number;
  withTreasuryDisclaimer?: number;
  withApexDisclaimer?: number;
  withDITaxAlphaDisclaimer?: number;
  withLowerFeesDisclaimer?: number;
  withETFExpenseRatioDisclaimer?: number;
  withVariableLTVDisclaimer?: number;
  withCapGainTaxRateDisclaimer?: number;
  withHypotheticalDataDisclaimer?: number;
  withDIResultsDisclaimer?: number;
  withDICustomizationDisclaimer?: number;
  withLowFeesLowMinimumsDisclaimer?: number;
  withFortyPercentDisclaimer?: boolean;
  withFortyPercentFootnote?: number;
}> = ({
  className,
  showBorders = true,
  withAffiliateDisclaimer,
  withTwoXMoreDisclaimer,
  withLowerFeesDisclaimerSPY,
  withTreasuryDisclaimer,
  withApexDisclaimer,
  withDITaxAlphaDisclaimer,
  withLowerFeesDisclaimer,
  withETFExpenseRatioDisclaimer,
  withVariableLTVDisclaimer,
  withCapGainTaxRateDisclaimer,
  withHypotheticalDataDisclaimer,
  withDIResultsDisclaimer,
  withDICustomizationDisclaimer,
  withLowFeesLowMinimumsDisclaimer,
  withFortyPercentDisclaimer,
  withFortyPercentFootnote,
}) => {
  return (
    <footer
      className={classNames(
        "text-frecBlack/50 bg-frecBeige grid grid-cols-[auto_minmax(0,_86rem)_auto]",
        className
      )}
    >
      <div className="">{/* empty */}</div>
      <div
        className={classNames(
          "relative flex flex-col gap-4 px-6 py-8 text-xs lg:px-16 lg:mx-12 pb-9",
          showBorders ? " lg:border-x dotted-v" : ""
        )}
        data-nosnippet
      >
        {withTwoXMoreDisclaimer ? (
          <p id={FootnoteAnchor.TwoXMoreDisclaimer}>
            <sup>{withTwoXMoreDisclaimer}</sup> “Up to 2x more” refers to
            results generated by Frec’s S&P 500 direct indexing model of 38.4%
            in tax losses harvested for Frec’s direct indexing portfolio
            compared to 20.2% for an ETF-to-ETF tax loss harvesting strategy.
            These results are hypothetical, do not reflect actual investment
            results, and are not a guarantee of future results. They were
            generated with a one-time $50,000 investment into Frec’s S&P 500
            direct indexing strategy and an ETF strategy trading between SPY and
            IVV for a ten year time frame of ninety day increments from
            12/17/2003-07/25/2023. The simulations considered Frec’s 0.10% fee
            structure and a 0.25% fee structure for the ETF strategy.
            Wealthfront and Betterment are roboadvisers that utilize an
            ETF-to-ETF tax loss harvesting strategy.
          </p>
        ) : null}
        {withLowerFeesDisclaimerSPY ? (
          <p id={FootnoteAnchor.LowerFeesDisclaimerSPY}>
            <sup>{withLowerFeesDisclaimerSPY}</sup> Data as of 09/20/2023. Fees
            for traditional brokerage refers to the largest U.S. brokerage firms
            that offer direct-to-consumer direct indexing products. 1.0% for
            wealth advisors: “What to know about financial costs” U.S. News &
            World Report, July 27, 2023. SPY is an exchange traded fund and not
            a traditional brokerage firm or wealth advisor. SPY is one of four
            ETFs that track the S&P 500 index and used as an example because
            it’s the largest and first ETF to track the S&P 500 index.
            Information on the other ETFs can be found at:{" "}
            <TextLink href="https://www.etf.com/topics/sp-500" target="_blank">
              https://www.etf.com/topics/sp-500
            </TextLink>
            .
          </p>
        ) : null}
        {withTreasuryDisclaimer ? (
          <p id={FootnoteAnchor.TreasuryDisclaimer}>
            <sup>{withTreasuryDisclaimer}</sup> As of{" "}
            {new Date().toLocaleDateString("en-us", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
            . Yield based on the highest 7-Day SEC Yield currently available
            among Frec’s Treasury options. Certain funds have specific
            investment minimums, which can be up to $1,000. Investors who invest
            amounts below these minimums may experience lower yields than those
            advertised. Yields are subject to change and will fluctuate over
            time.
          </p>
        ) : null}
        {withApexDisclaimer ? (
          <p id={FootnoteAnchor.ApexDisclaimer}>
            <sup>{withApexDisclaimer}</sup> As of 03/13/2023.
          </p>
        ) : null}
        {withDITaxAlphaDisclaimer ? (
          <p id={FootnoteAnchor.DITaxAlphaDisclaimer}>
            <sup>{withDITaxAlphaDisclaimer}</sup> The 21x figure is based on a
            tax alpha of {alphaPercentStr}% above market performance and Frec’s
            0.10% annual fee. The {alphaPercentStr}% projection was generated by
            Frec’s Direct Indexing Model tracking the S&P 500 and is
            hypothetical, does not reflect actual investment results, and is not
            a guarantee of future results. The simulations were run to tax loss
            harvest on a weekly basis in a ten-year time frame of ninety day
            increments from 12/17/2003 – 6/10/22 with a $50,000 initial deposit.
            The simulations averaged at the end of year ten resulted in a 45.1%
            accumulated tax loss savings that were reinvested with a 42.3% tax
            rate, and includes Frec’s 0.10% fee, resulting in a{" "}
            {alphaPercentStr}% additional return. Past performance does not
            guarantee future results.
          </p>
        ) : null}
        {withLowerFeesDisclaimer ? (
          <p id={FootnoteAnchor.LowerFeesDisclaimer}>
            <sup>{withLowerFeesDisclaimer}</sup> Data as of 09/20/2023. Fees for
            traditional brokerage refers to the largest U.S. brokerage firms
            that offer direct-to-consumer direct indexing products. 1.0% for
            wealth advisors: “What to know about financial costs” U.S. News &
            World Report, July 27, 2023.
          </p>
        ) : null}
        {withETFExpenseRatioDisclaimer ? (
          <p id={FootnoteAnchor.ETFExpenseRatioDisclaimer}>
            <sup>{withETFExpenseRatioDisclaimer}</sup> Expense ratio data as of
            09/20/2023. SPY is an exchange traded fund and not a traditional
            brokerage firm or wealth advisor. SPY is one of four ETFs that track
            the S&P 500 index and used as an example because it’s the largest
            and first ETF to track the S&P 500 index. Information on the other
            ETFs that track the S&P 500 index can be found at:{" "}
            <TextLink href="https://www.etf.com/topics/sp-500" target="_blank">
              https://www.etf.com/topics/sp-500
            </TextLink>
            .
          </p>
        ) : null}
        {withVariableLTVDisclaimer ? (
          <p id={FootnoteAnchor.VariableLTVDisclaimer}>
            <sup>{withVariableLTVDisclaimer}</sup> Frec allows you to borrow a
            maximum of 70% of your portfolio value. The amount available to
            borrow (maximum line of credit) is based on the positions in your
            portfolio (loan to value of positions range from 0% to 75%). The
            initial borrow amount available is typically 50% of your portfolio
            value. This amount can gradually increase to a maximum of 70% when
            your portfolio appreciates in value. If you transfer assets from
            another broker, the initial borrow amount received can be higher
            than 50% if you have accumulated sufficient value in your Special
            Memorandum Account (SMA) at the delivering broker and the value is
            sent to us with the transfer. If you are transferring assets from a
            portfolio margin account, please contact help@frec.com to update
            your SMA.
          </p>
        ) : null}
        {withCapGainTaxRateDisclaimer ? (
          <p id={FootnoteAnchor.CapGainTaxRateDisclaimer}>
            <sup>{withCapGainTaxRateDisclaimer}</sup> This 33.33% tax rate
            assumes a federal long term gains tax rate of 20% plus an additional
            13.33% of state tax and NIIT.
          </p>
        ) : null}
        {withHypotheticalDataDisclaimer ? (
          <p id={FootnoteAnchor.HypotheticalDataDisclaimer}>
            <sup>{withHypotheticalDataDisclaimer}</sup> This is obtained from
            hypothetical data. We don’t provide tax advice.
          </p>
        ) : null}
        {withDIResultsDisclaimer ? (
          <p id={FootnoteAnchor.DIResultsDisclaimer}>
            <sup>{withDIResultsDisclaimer}</sup> Results are generated by Frec’s
            direct indexing model tracking the S&P 500. They are hypothetical,
            do not reflect actual investment results, and not a guarantee of
            future results. The simulations used a $50,000 one-time deposit and
            harvested tax losses on a weekly basis. It ran through nine-day
            increments for two 10-year periods of 12/17/2003-10/23/2013 and
            3/16/2004-01/23/2014 for 40% harvested losses and one 10-year period
            of 12/17/03-7/25/23 for 2x tax losses harvested for DI vs. ETF. The
            40% was based on an average at the end of year ten. The ETF strategy
            comparison traded between SPY and IVV. Past performance does not
            guarantee future results.
          </p>
        ) : null}
        {withDICustomizationDisclaimer ? (
          <p id={FootnoteAnchor.DICustomizationDisclaimer}>
            <sup>{withDICustomizationDisclaimer}</sup> As of Jan 2024, customers
            can exclude up to 2 sectors and add or exclude up to 10 stocks for
            the S&P 500 Index and add or exclude up to 10 stocks for the S&P 500
            Information Technology Index.
          </p>
        ) : null}
        {withLowFeesLowMinimumsDisclaimer ? (
          <p id={FootnoteAnchor.LowFeesLowMinimumsDisclaimer}>
            <sup>{withLowFeesLowMinimumsDisclaimer}</sup> Data as of 09/20/2023.
            Fees for traditional brokerage refers to the largest U.S. brokerage
            firms that offer direct-to-consumer direct indexing products. 1.0%
            for wealth advisors: “What to know about financial costs” U.S. News
            & World Report, July 27, 2023. $100,000 refers to Wealthfront’s and
            Charles Schwab’s direct indexing account minimum, some firms require
            less.
          </p>
        ) : null}
        {withFortyPercentFootnote ? (
          <p id={FootnoteAnchor.FortyPercentFootnote}>
            <sup>{withFortyPercentFootnote}</sup> 40% tax losses harvested from
            a portfolio is based on simulation results from Frec’s direct index
            model tracking the S&P 500 index. The results are hypothetical, do
            not reflect actual invesment results, and are not a guarantee of
            future results. The simulations were run to tax loss harvest on a
            weekly basis in a ten-year time frame of ninety-day increments from
            12/17/2003 - 06/10/2022 with a $50,000 initial deposit. The
            simulations averaged at the end of year ten resulted in a 40%
            accumulated tax loss savings and does not include Frec’s 0.10% fee.
          </p>
        ) : null}
        {withFortyPercentDisclaimer ? (
          // This disclaimer appears on the root landing page because of a
          // marketing newsletter that will be sent out. 8 March 2024
          // FREC-3397
          <p>
            40% tax losses harvested from a portfolio is based on simulation
            results from Frec’s direct index model tracking the S&P 500 index.
            The results are hypothetical, do not reflect actual invesment
            results, and are not a guarantee of future results. The simulations
            were run to tax loss harvest on a weekly basis in a ten-year time
            frame of ninety-day increments from 12/17/2003 - 06/10/2022 with a
            $50,000 initial deposit. The simulations averaged at the end of year
            ten resulted in a 40% accumulated tax loss savings and does not
            include Frec’s 0.10% fee.
          </p>
        ) : null}
        <p>
          Frec is making sophisticated investing strategies simple and
          accessible. By using frec.com, you accept our{" "}
          <TextLink href={FrecWebPath.terms} target="_blank">
            Terms of Use
          </TextLink>{" "}
          and{" "}
          <TextLink href={FrecWebPath.privacy} target="_blank">
            Privacy Policy
          </TextLink>
          . Frec is only available to US residents. Frec refers to Frec Markets,
          Inc., and its wholly owned subsidiaries, Frec Securities LLC and Frec
          Advisers LLC. Read about the services and differences between the
          entities in our{" "}
          <TextLink href={DocumentLinks["Form CRS"]} target="_blank">
            Form CRS
          </TextLink>
          .
        </p>
        {withAffiliateDisclaimer ? (
          <p>
            If you clicked on an Affiliate link to arrive on this page, the
            affiliate that owns the link you clicked will receive cash
            compensation from Frec Markets, Inc (parent company of Frec Advisers
            LLC and Frec Securities LLC) [altogether referred to as “Frec”] for
            sponsored advertising materials, which creates an incentive that
            results in material conflict of interest. The Affiliate is not a
            client and this is a paid endorsement. The referenced Affiliate and
            Frec are not associated with one another and have no formal
            relationship outside of this arrangement. The referring Affiliates
            receive $3-$175 for every Frec customer who signs up for an account
            and completes certain actions.
          </p>
        ) : null}
        <Expandable
          head={<span className={"text-sm"}>Important disclosures</span>}
        >
          <div className="flex flex-col gap-3">
            {withAffiliateDisclaimer ? (
              <>
                <p>
                  Any links provided by this Affiliate are not intended to imply
                  that Frec endorses, sponsors, promotes and/or is affiliated
                  with the owns of or participants in those sites, or endorses
                  any information contained on those sites, unless expressly
                  stated otherwise.
                </p>
                <p>
                  Reference to a 2% additional return refers to simulations
                  generated by Frec’s Direct Index model tracking the S&P 500
                  index and is hypothetical, does not reflect actual investment
                  results, and is not a guarantee of future results. Simulations
                  were run on a weekly basis in a ten-year time frame of
                  ninety-day increments from 12/17/2003-06/10/2022 with a
                  $50,000 one time initial deposit. The results averaged at year
                  ten resulted in a 45.1% accumulated tax loss savings that were
                  reinvested with a 42.3% tax rate, and includes Frec’s 0.10%
                  fee, resulting in a 2.11% additional annual return. Use 45.1%
                  tax loss harvested from a $100 amount and a 42.3% tax rate to
                  obtain the result of $19 savings from $100. Results may vary.
                </p>
              </>
            ) : null}
            <p>
              “Index investing that gets you more” refers to Frec’s Direct
              Indexing strategies ability to tax loss harvest in addition to
              tracking the S&P 500 or S&P 500 Information Technology indices.
            </p>
            <p>
              Direct index strategies and treasury advisory services are
              provided by Frec Advisers LLC (“Frec Advisers”), an SEC-registered
              investment adviser. See Frec Advisers{" "}
              <TextLink
                className="underline"
                href={DocumentLinks["Form ADV"]}
                target="_blank"
              >
                Form ADV Part IIA
              </TextLink>{" "}
              for additional information, including details on the direct
              indexing strategies. Frec Advisers does not guarantee that the
              results of its advice, recommendations, or the objectives of its
              direct index or cash management strategies will be achieved. We
              make no assurance that the investment process will consistently
              lead to successful investing. Before you invest, you should
              carefully review and consider your investment objectives as well
              as the risks, charges, and expenses of the underlying securities.
              There is a {formatUsd(GENERAL_MINIMUM_FOR_DIRECT_INDEXING)}{" "}
              account minimum required to participate in Frec’s direct indexing
              strategies.
            </p>
            <p>
              The S&P 500 and S&P 500 Information Technology Indices are
              products of S&P Dow Jones Indices LLC or its affiliates (“SPDJI”)
              and have been licensed for use by Frec Markets, Inc. Standard &
              Poors
              <sup>®</sup> and S&P<sup>®</sup> are registered trademarks of
              Standard & Poor’s Financial Services LLC (“S&P”); Dow Jones
              <sup>®</sup> is a registered trademark of Dow Jones Trademark
              Holdings LLC (“Dow Jones”); and these trademarks have been
              licensed for use by SPDJI and sublicensed for certain purposes by
              Frec Markets, Inc. Frec’s Direct Indexing is not sponsored,
              endorsed, sold or promoted by SPDJI, Dow Jones, S&P, or their
              respective affiliates and none of such parties make any
              representation regarding the advisability of investing in such
              products nor do they have any liability for any errors, omissions,
              or interruptions of the S&P 500 Indices.
            </p>
            <p>
              The CRSP<sup>®</sup> indices are products of Center of Research in
              Security Prices, LLC, the Russell<sup>®</sup> indices are products
              of Frank Russell Company, the MVIS<sup>®</sup> index is a product
              of Van Eck Associates Corporation and all have been licensed for
              use by Frec.
            </p>
            <p>
              Brokerage products and services are offered by Frec Securities LLC
              (“Frec Securities”), an SEC registered broker-dealer, member
              FINRA/SIPC. Member of SIPC, which protects securities customers of
              its members up to $500,000 (including $250,000 for claims for
              cash). Explanatory brochure available upon request or at{" "}
              <TextLink href="https://www.sipc.org/" target="_blank">
                www.sipc.org
              </TextLink>
              . Investing involves risk, including the risk of loss. Borrowing
              on margin can add to those risks, including losing more than
              invested. Please read Frec’s{" "}
              <TextLink href={FrecWebPath.disclosures} target="_blank">
                Margin Disclosure
              </TextLink>{" "}
              before borrowing. Margin accounts require a $2,000 minimum. Any
              stocks shown are for informational purposes and should not be
              considered a trade recommendation.
            </p>
            <p>
              Frec Securities does not provide investment advice. Neither Frec
              Securities nor Frec Advisers provide tax, or legal advice. Frec’s
              website, brokerage, and advisory services are not intended for
              persons of any jurisdiction where Frec is not authorized to do
              business. Tax savings will vary from client to client due to many
              factors including market conditions, tax characteristics of
              securities, client-imposed investment restrictions, client tax
              rate or status, and changes in tax regulations.
            </p>
            <p>
              <TextLink href="https://www.xignite.com" target="_blank">
                Market data
              </TextLink>{" "}
              by Xignite. Money market fund data powered by{" "}
              <TextLink href="https://quotemedia.com/" target="_blank">
                QuoteMedia
              </TextLink>
              . All rights reserved. View the{" "}
              <TextLink
                href="https://www.quotemedia.com/legal/tos"
                target="_blank"
              >
                terms of use
              </TextLink>
              . Data delayed 15 minutes unless indicated.
            </p>
          </div>
        </Expandable>
      </div>
      <div className="">{/* empty */}</div>
    </footer>
  );
};
